const productsConstants = {
  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',

  GET_PRODUCTS_SKIP: 'GET_PRODUCTS_SKIP',

  GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',

  RESET_PRODUCT: 'RESET_PRODUCT',

  GET_PRODUCT_SUSCRIPCION_REQUEST: 'GET_PRODUCT_SUSCRIPCION_REQUEST',
  GET_PRODUCT_SUSCRIPCION_SUCCESS: 'GET_PRODUCT_SUSCRIPCION_SUCCESS',
  GET_PRODUCT_SUSCRIPCION_FAILURE: 'GET_PRODUCT_SUSCRIPCION_FAILURE',

  GET_PRODUCT_API_REQUEST: 'GET_PRODUCT_API_REQUEST',
  GET_PRODUCT_API_SUCCESS: 'GET_PRODUCT_API_SUCCESS',
  GET_PRODUCT_API_FAILURE: 'GET_PRODUCT_API_FAILURE',

  GET_PRODUCT_API_SKIP: 'GET_PRODUCT_API_SKIP',

  GET_SUBSCRIPTIONS_REQUEST: 'GET_SUBSCRIPTIONS_REQUEST',
  GET_SUBSCRIPTIONS_SUCCESS: 'GET_SUBSCRIPTIONS_SUCCESS',
  GET_SUBSCRIPTIONS_FAILURE: 'GET_SUBSCRIPTIONS_FAILURE',

};

export default productsConstants;
