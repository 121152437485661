import React from 'react';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import SettingsInputSvideoOutlinedIcon from '@mui/icons-material/SettingsInputSvideoOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import { ReactComponent as AccountIcon } from '../../static/icons/AccountIcon.svg';
import { ReactComponent as Logo } from '../../static/img/logo.svg';
import { ReactComponent as LogoWhite } from '../../static/img/logoWhite.svg';
import { ReactComponent as ApiMarketIcon } from '../../static/img/LogoApiMarket.svg';
import { ReactComponent as FintechIcon } from '../../static/img/LogoFintech.svg';
import { ReactComponent as FintechWhiteIcon } from '../../static/img/LogoFintechWhite.svg';
import { ReactComponent as SettingsIcon } from '../../static/icons/SettingsIcon.svg';
import { ReactComponent as CodeIcon } from '../../static/icons/one-person-working.svg';
import { ReactComponent as LaptopCodeIcon } from '../../static/icons/LaptopCodeIcon.svg';
import { ReactComponent as SettingsSwitchIcon } from '../../static/icons/sliders-screen.svg';
import { ReactComponent as AddUserIcon } from '../../static/icons/conference-call-skype.svg';
import { ReactComponent as ChevronRightIcon } from '../../static/icons/ChevronRightIcon.svg';
import { ReactComponent as ChevronRightFullIcon } from '../../static/icons/flecha-derecha.svg';
import { ReactComponent as ApiMarketWhiteIcon } from '../../static/img/LogoApiMarketWhite.svg';
import { ReactComponent as CalculatorPorcentage } from '../../static/icons/arrows-change.svg';
import { ReactComponent as Archivist } from '../../static/icons/laptop-checks_0.svg';
import { ReactComponent as Elearning } from '../../static/icons/your-trace.svg';
import { ReactComponent as HandShake } from '../../static/icons/hands-shake.svg';

function CustomIcon({ name, ...rest }) {

  const renderIcon = {
    account: {
      render: (
        <AccountIcon />
      ),
    },
    logo: {
      render: (
        <Logo />
      ),
    },
    logowhite: {
      render: (
        <LogoWhite />
      ),
    },
    apimarket: {
      render: (
        <ApiMarketIcon />
      ),
    },
    apimarketwhite: {
      render: (
        <ApiMarketWhiteIcon />
      ),
    },
    fintech: {
      render: (
        <FintechIcon />
      ),
    },
    fintechwhite: {
      render: (
        <FintechWhiteIcon />
      ),
    },
    cog: {
      render: (
        <SettingsIcon />
      ),
    },
    code: {
      render: (
        <CodeIcon />
      ),
    },
    laptopcode: {
      render: (
        <LaptopCodeIcon />
      ),
    },
    settings: {
      render: (
        <SettingsSwitchIcon />
      ),
    },
    adduser: {
      render: (
        <AddUserIcon />
      ),
    },
    chevron_right: {
      render: (
        <ChevronRightIcon />
      ),
    },
    chevron_right_full: {
      render: (
        <ChevronRightFullIcon />
      ),
    },
    integration: {
      render: (
        <CalculatorPorcentage />
      ),
    },
    catalogo: {
      render: (
        <Archivist />
      ),
    },
    elearning: {
      render: (
        <Elearning />
      ),
    },
    idea: {
      render: (
        <LightbulbOutlinedIcon sx={{ width: 60, height: 60 }} />
      ),
    },
    link: {
      render: (
        <AttachmentOutlinedIcon sx={{ width: 60, height: 60 }} />
      ),
    },
    like: {
      render: (
        <ThumbUpOutlinedIcon sx={{ width: 60, height: 60 }} />
      ),
    },
    setting: {
      render: (
        <SettingsInputSvideoOutlinedIcon sx={{ width: 60, height: 60 }} />
      ),
    },
    add: {
      render: (
        <LibraryAddOutlinedIcon sx={{ width: 60, height: 60 }} />
      ),
    },
    world: {
      render: (
        <LanguageOutlinedIcon sx={{ width: 60, height: 60 }} />
      ),
    },
    organizer: {
      render: (
        <SchemaOutlinedIcon sx={{ width: 60, height: 60 }} />
      ),
    },
    handshake: {
      render: (
        <HandShake />
      ),
    },
    default: {
      render: (
        <CodeIcon />
      ),
    },
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {renderIcon[name] ? (renderIcon[name].render) : null}
    </>
  );
}

export default CustomIcon;
