const blogConstants = {
  GET_ALL_BLOG_REQUEST: 'GET_ALL_BLOG_REQUEST',
  GET_ALL_BLOG_SUCCESS: 'GET_ALL_BLOG_SUCCESS',
  GET_ALL_BLOG_FAILURE: 'GET_ALL_BLOG_FAILURE',

  FILTER_BLOGS: 'FILTER_BLOGS',

  GET_BLOG_REQUEST: 'GET_BLOG_REQUEST',
  GET_BLOG_SUCCESS: 'GET_BLOG_SUCCESS',
  GET_BLOG_FAILURE: 'GET_BLOG_FAILURE',

  GET_BLOG_DATA_ALL_REQUEST: 'GET_BLOG_DATA_ALL_REQUEST',
  GET_BLOG_DATA_ALL_SUCCESS: 'GET_BLOG_DATA_ALL_SUCCESS',
  GET_BLOG_DATA_ALL_FAILURE: 'GET_BLOG_DATA_ALL_FAILURE',

  RESET_BLOG: 'RESET_BLOG',
};

export default blogConstants;
