const termConstants = {
  GET_ALL_TERM_REQUEST: 'GET_ALL_TERM_REQUEST',
  GET_ALL_TERM_SUCCESS: 'GET_ALL_TERM_SUCCESS',
  GET_ALL_TERM_FAILURE: 'GET_ALL_TERM_FAILURE',

  GET_ALL_LEGAL_NOTICE_REQUEST: 'GET_ALL_LEGAL_NOTICE_REQUEST',
  GET_ALL_LEGAL_NOTICE_SUCCESS: 'GET_ALL_LEGAL_NOTICE_SUCCESS',
  GET_ALL_LEGAL_NOTICE_FAILURE: 'GET_ALL_LEGAL_NOTICE_FAILURE',
};

export default termConstants;
