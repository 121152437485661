import React from 'react';
import { Helmet } from 'react-helmet';
import classes from './wiki.module.scss';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import BannerStatic from '../../../components/Banner/BannerStatic';
import config from '../../../services/config';

function Wiki(props) {
  return (
    <>
      <Helmet>
        <title>Documentación de APIs a3developers | Wolters Kluwer</title>
        <meta name='description' content='Accede a la documentación detallada de las APIs en a3developers de Wolters Kluwer. Información para desarrolladores.' />
        <link rel='canonical' href={`${config.baseUrlFront}/documentacion`} />
      </Helmet>

      <div className='div__container__pt'>
        <BannerStatic
          title='Documentación'
          img=''
        />
        <div className='w-full' style={{ marginTop: '1em' }}>
          <Breadcrumb crumbs={[
            { title: 'Inicio', link: '/' },
            { title: 'Documentación', link: '/documentacion' },
          ]}
          />
        </div>
        <iframe
          className={classes.wiki__iframe}
          title='Wiki'
          src='./doc'
        />
      </div>
    </>
  );
}

export default Wiki;
