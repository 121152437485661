// import { Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Button from '../../../components/Buttons/Button';
import InputUI from '../../../components/Input/InputUI/InputUI';
import CustomFooter from '../../../components/common/CustomFooter/CustomFooter';
import { resetPasswordWithTicket } from '../../../redux/actions/userAction';
/*import LogoAlt from '../../../static/img/logoAlt.svg';*/
import classes from './confirm-password.module.scss';

function ResetPassword(props) {

  const { responseResetPwd, responseResetPwdError } = useSelector((state) => state.user);
  const [hasErrors, setHasErrors] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryParams = {
    id: searchParams.get('userid'),
    ticketid: searchParams.get('ticketid'),
    ticket: searchParams.get('ticket'),
  };

  useEffect(() => {
    if (Object.keys(responseResetPwdError).length > 0) {
      setHasErrors(true);
    }
  }, [responseResetPwdError]);

  useEffect(() => {
    if (Object.keys(responseResetPwd).length > 0) {
      setHasSuccess(true);
    }
  }, [responseResetPwd]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: (values) => {
      const data = {
        properties: {
          password: values.password,
        },
      };
      dispatch(resetPasswordWithTicket(queryParams, data)).then((result) => {
        console.log(result);
      }).catch((err) => {
        console.log(err);
      });
      formik.values.password = '';
      formik.values.confirmPassword = '';
    },
    validate: (values) => {
      const errors = {};

      // Validar longitud mínima
      if (values.password.length < 8) {
        errors.password = 'La contraseña debe tener al menos 8 caracteres';
      }

      // Validar al menos una letra mayúscula
      if (!/[A-Z]/.test(values.password)) {
        errors.password = 'La contraseña debe contener al menos una letra mayúscula';
      }

      // Validar al menos una letra minúscula
      if (!/[a-z]/.test(values.password)) {
        errors.password = 'La contraseña debe contener al menos una letra minúscula';
      }

      // Validar al menos un número
      if (!/[0-9]/.test(values.password)) {
        errors.password = 'La contraseña debe contener al menos un número';
      }

      // Validar al menos un carácter especial (puedes definir tu propia lista de caracteres especiales)
      if (!/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(values.password)) {
        errors.password = 'La contraseña debe contener al menos un carácter especial';
      }

      // Validar coincidencia entre contraseña y confirmación
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Las contraseñas no coinciden';
      }

      return errors;
    },
  });

  return (
    <div>
      <div className={classes.navbar}>
        <div className={classes.navbar__content}>
          {/*<img src={LogoAlt} alt='' />*/}
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapper__content}>
          <div className={classes.wrapper__content__text}>
            <div className='container mt-10'>
              <div className='row'>
                <div className='flex-sm-12 flex-md-12'>
                  <h1>Ingresa tu nueva contraseña</h1>
                  {
                    hasErrors &&
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Alert severity='error'>
                      <p>Es posible que el enlace para actualizar tu contraseña haya expirado o tu cuenta de correo aún no se encuentre confirmada </p>
                    </Alert>
                  }
                  {
                    hasSuccess &&
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Alert severity='success'>
                      Tu contraseña ha sido cambiada
                    </Alert>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='flex-sm-12 flex-md-12'>
                {formik.errors.password || formik.errors.confirmPassword ? (
                  <Alert severity='error'>
                    <p>{formik.errors.password}</p>
                    <p>{formik.errors.confirmPassword}</p>
                  </Alert>
                ) : null}
              </div>
            </div>
          </div>
          <div className='container'>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='row justify-center'>
                <div className='flex-sm-12 flex-md-12 py-5'>
                  <InputUI
                    name='password'
                    id='password'
                    label='Nueva contraseña*'
                    type='password'
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    required={true}
                  />
                </div>
                <div className='flex-sm-12 flex-md-12 pb-10'>
                  <InputUI
                    name='confirmPassword'
                    label='Confirmar contraseña*'
                    type='password'
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    required={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='flex-sm-12 flex-md-12 display_flex flex-end'>
                  <Button styles='primary' type='submit'>
                    Restablecer contraseña
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}

ResetPassword.propTypes = {};

export default ResetPassword;
