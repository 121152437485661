/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getApiContent } from '../../../redux/actions/apiAction';
import { getLibraries, getApisUnsecure } from '../../../redux/actions/libraryAction';
import ApisPaginated from '../../../components/ApisPaginated';
import classes from './apis.module.scss';
import SkeletonComponent from '../../../components/SkeletonComponent/SkeletonComponent';
import BannerStatic from '../../../components/Banner/BannerStatic';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import config from '../../../services/config';

function Apis({ setIsOpen }) {
  const { libraries, filters, loadingLibraries, apisUnsecureRes } = useSelector((state) => state.library);
  const dispatch = useDispatch();
  const { apiPage } = useSelector((state) => state.api);

  useEffect(() => {
    if (apiPage && Object.keys(apiPage).length === 0) {
      dispatch(getApiContent());
    }
  }, []);

  useEffect(() => {
    if (libraries && libraries.length === 0 && Object.keys(filters).length === 0) {
      dispatch(getLibraries());
    }

    if (apisUnsecureRes && apisUnsecureRes.length === 0) {
      dispatch(getApisUnsecure());
    }

  }, []);

  const compareArrays = (array1, array2) => {
    return array1.filter((a) => {
      return array2.some((b) => {
        return a.slug === b.name;
      });
    });
  };

  const fApis = libraries && libraries.length > 0 && apisUnsecureRes && apisUnsecureRes.length > 0 ? compareArrays(libraries, apisUnsecureRes) : [];

  return (
    <>
      <Helmet>
        <title>Catálogo de APIs de a3developers | Wolters Kluwer</title>
        <meta name='description' content='Explora nuestro catálogo de APIs en a3developers de Wolters Kluwer. Descubre las API disponibles.' />
        <link rel='canonical' href={`${config.baseUrlFront}/apis`} />
      </Helmet>

      <div className='div__container__pt' style={{ background: '#F5F6F8' }}>
        <BannerStatic
          title='Catálogo de APIs'
          img=''
        />
        <div className='w-full' style={{ paddingTop: '1em' }}>
          <Breadcrumb crumbs={[
            { title: 'Inicio', link: '/' },
            { title: 'Catálogo de APIs', link: '/apis' },
          ]}
          />
        </div>
        <div className='container'>
          <section className={classes.wrapper}>
            <div className='w-full' style={{ paddingBottom: '1em' }}>
              {loadingLibraries === false && libraries ? (
                libraries.length > 0 ? (
                  <ApisPaginated
                    apis={fApis}
                    itemsPerPage={8}
                  />
                ) : (
                  <section
                    style={{
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '2rem',
                      }}
                    >
                      <h1>Información no disponible</h1>
                    </div>
                  </section>
                )
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <SkeletonComponent />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Apis;
