import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../components/Modal';
import CreateAccount from '../../../components/Forms/CreateAccount';
import SkeletonComponent from '../../../components/SkeletonComponent/SkeletonComponent';
import Alert from '../../../components/Alert';
import classes from './register.module.scss';
import { getHomeContent } from '../../../redux/actions/homeAction';

function Register({ setOpenForm, setIsOpen }) {
  const { loadingSignUp, signUpData, responseError } = useSelector((state) => state.user);
  const { homePage } = useSelector((state) => state.home);
  const dispatch = useDispatch();

  useEffect(() => {
    if (signUpData && Object.keys(signUpData).length > 0) {
      setIsOpen(true);
      setOpenForm(false);
    }
  }, [signUpData]);

  useEffect(() => {
    if (homePage && Object.keys(homePage).length === 0) {
      dispatch(getHomeContent());
    }
  }, []);

  const filterButtons = homePage && homePage.contentSections ? homePage.contentSections.filter((item) => item.title === 'botones-autenticacion') : [];

  return (
    <Modal setOpen={setOpenForm} maxWidth='md'>
      {loadingSignUp === false ? (
        <div>
          <h1 className={classes.login__title}>Crea tu Cuenta</h1>
          <div className='container'>
            <div className='row'>
              <div className='flex-sm-12 flex-md-12 flex-lg-12'>
                {
                  Object.keys(signUpData).length > 0 && Object.keys(responseError).length === 0 ?
                    (
                      <Alert
                        css_styles={{ custom_padding: 'p-4', custom_margin: '' }}
                        alert_type='alert__success'
                        title='Revisa tu cuenta de correo'
                        msg='Para completar el registro, es necesario confirmar tu cuenta de correo'
                      />
                    ) : Object.keys(signUpData).length === 0 && Object.keys(responseError).length > 0 ? (
                      <Alert
                        css_styles={{ custom_padding: 'p-4', custom_margin: '' }}
                        alert_type='alert__danger'
                        title='Error al registrarte'
                        msg='El usuario ya esta dado de alta'
                      />
                    ) : (null)
                }
              </div>
            </div>
          </div>
          <CreateAccount setOpenForm={setOpenForm} setIsOpen={setIsOpen} classButton={filterButtons[0].buttons[1]} />
        </div>
      ) : (
        <SkeletonComponent />
      )}
    </Modal>
  );
};

export default Register;
