const subscriptionsConstants = {
  GET_ALL_SUBSCRIPTIONS_USER_REQUEST: 'GET_ALL_SUBSCRIPTIONS_USER_REQUEST',
  GET_ALL_SUBSCRIPTIONS_USER_SUCCESS: 'GET_ALL_SUBSCRIPTIONS_USER_SUCCESS',
  GET_ALL_SUBSCRIPTIONS_USER_FAILURE: 'GET_ALL_SUBSCRIPTIONS_USER_FAILURE',

  CREATE_SUBSCRIPTIONS_USER_REQUEST: 'CREATE_SUBSCRIPTIONS_USER_REQUEST',
  CREATE_SUBSCRIPTIONS_USER_SUCCESS: 'CREATE_SUBSCRIPTIONS_USER_SUCCESS',
  CREATE_SUBSCRIPTIONS_USER_FAILURE: 'CREATE_SUBSCRIPTIONS_USER_FAILURE',

  RENAME_SUBSCRIPTIONS_REQUEST: 'RENAME_SUBSCRIPTIONS_REQUEST',
  RENAME_SUBSCRIPTIONS_SUCCESS: 'RENAME_SUBSCRIPTIONS_SUCCESS',
  RENAME_SUBSCRIPTIONS_FAILURE: 'RENAME_SUBSCRIPTIONS_FAILURE',

  CANCEL_SUBSCRIPTIONS_SUCCESS: 'CANCEL_SUBSCRIPTIONS_SUCCESS',
  CANCEL_SUBSCRIPTIONS_FAILURE: 'CANCEL_SUBSCRIPTIONS_FAILURE',

  RESET_SUBSCRIPTIONS_USER: 'RESET_SUBSCRIPTIONS_USER',

  REGENERATE_SUBSCRIPTIONS_SUCCESS: 'REGENERATE_SUBSCRIPTIONS_SUCCESS',
  REGENERATE_SUBSCRIPTIONS_REQUEST: 'REGENERATE_SUBSCRIPTIONS_REQUEST',

  GET_SUBSCRIPTIONS_SKIP: 'GET_SUBSCRIPTIONS_SKIP',

  GET_DETAIL_SUBSCRIPTIONS_SKIP: 'GET_DETAIL_SUBSCRIPTIONS_SKIP',
};

export default subscriptionsConstants;
