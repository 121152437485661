import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SkeletonComponent from '../../../components/SkeletonComponent/SkeletonComponent';
import classes from './subscription-form.module.scss';
import './form.scss';

function SubscriptionForm() {
  console.log('SubscriptionForm');
  const subscriptions = {
    '8fd82d57-5871-4cbc-88e4-2cf071b6833e': {
      title: 'a3factura Conectia',
    },
    '5ea30d25-ce1a-4e47-afd0-5e9be22c1965': {
      title: 'a3innuva Contabilidad Conectia',
    },
    '97f7f4c2-1d01-4200-9ce0-8ef6dd931645': {
      title: 'a3innuva ERP Conectia',
    },
    '503852c4-c108-40e4-b012-e6915be1e26d': {
      title: 'a3innuva Nómina Conectia',
    },
  };
  const params = useParams();
  const [subscriptionForm, setSubscriptionForm] = useState();
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    setSubscriptionForm(subscriptions[params.id]);
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '2514384',
          formId: params.id,
          region: 'na1',
          target: '#hubspotForm',
        });
        setFormLoaded(true);
      }
    });
  }, []);

  return (
    (subscriptionForm && formLoaded) ? (
      <>
        <Helmet>
          <title>{subscriptionForm?.title}</title>
        </Helmet>
        <div className={classes.subscription_detail}>
          <Container fixed sx={{ paddingLeft: { xs: '0px', md: '59px !important' }, paddingRight: { xs: '0px', md: '97px !important' } }}>
            <h1 className='h2 text__primary__title font-weight-bold text-center'>{`Suscribete a ${subscriptionForm?.title}`}</h1>
            <div className={classes.subscription_detail__form}>
              <div id='hubspotForm' />
            </div>
          </Container>
        </div>

      </>
    ) : <SkeletonComponent />
  );
}

export default SubscriptionForm;
