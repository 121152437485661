/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import BannerStatic from '../../../components/Banner/BannerStatic';
import PartnerCard from '../../../components/Card/PartnerCard';
import SubscriptionCard from '../../../components/Card/SubscriptionCard';
import { getSubscriptions } from '../../../redux/actions/productsAction';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
//import classes from '../SubscriptionDetailContact/subscription-detail.module.scss';
import classes from './subscriptions.module.scss';
import config from '../../../services/config';
import Button from '../../../components/Buttons/Button';

function Subscriptions({ setOpenForm }) {

  /*const dispatch = useDispatch();
  const { subscriptionRes } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);*/

  /*const arrCardSubs = subscriptionRes && Object.keys(subscriptionRes).length > 0 ? subscriptionRes.map((item) => {
    const apis = item.library_apis.map((api) => {
      return {
        title: api.title,
        id: api.id,
      };
    });

    return {
      slug: item.slug,
      title: item.title,
      price: item.price,
      apis,
      btnLabel: item.btnLabel,
      benefits: item?.benefits,
      content: item.content,
      accentColor: item.accentColor,
      iframeSource: item.form,
    };
  }) : [];*/

  return (
    <>
      <Helmet>
        <title>Suscripciones y planes a3developers | Wolters Kluwer</title>
        <meta name='description' content='Descubre nuestras suscripciones y planes en a3developers de Wolters Kluwer. Elije el plan que mejor se adapte a ti.' />
        <link rel='canonical' href={`${config.divUrlFront}/suscripciones`} />
      </Helmet>

      <div className='div__container__pt'>
        <BannerStatic
          title='Suscripciones'
          img=''
        />
        <div className='w-full' style={{ marginTop: '1em' }}>
          <Breadcrumb crumbs={[
            { title: 'Inicio', link: '/' },
            { title: 'Suscripciones', link: '/suscripciones' },
          ]}
          />
        </div>
        <section className='container__fluid section__subs' style={{ backgroundColor: '#ededed', margin: '0px' }}>
          <div>
            <p style={{ fontStyle: 'italic', padding: '2rem 5rem 3rem', color: '#44546a', fontSize: '1.25rem' }}>
              <b>Conectia</b>
              {' '}
              te permite vincular tus soluciones cloud de Wolters Kluwer mediante API&#39;s y realizar tus propios desarrollos e integraciones.
              <br />
              Comienza ya a integrar de forma rápida y ágil con cualquier aplicación de terceros.
            </p>
          </div>
          <div className='row justify-center' style={{ width: '100%', margin: '0px' }}>
            {/*arrCardSubs.map((card, index) => {
              return card.slug === 'partner' ? (
                <div className={`flex-sm-12 flex-md-4 flex-lg-2 ${classes.flex_custom}`}>
                  <PartnerCard title='Partner' slug={card.slug} content={card.benefits?.markdown} />
                </div>
              ) : (
                <div className={`flex-sm-12 flex-md-4 flex-lg-2 ${classes.flex_custom}`}>
                  <SubscriptionCard items={card} setOpenForm={setOpenForm} />
                </div>
              );
            })*/}
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', margin: '0px 5rem' }}>
              <div className={classes.card}>
                <div className={classes.card__wrapper__subs}>
                  <div>
                    <div className={classes.card__header}>
                      <div className={classes.card__header__title}>
                        <h1>a3innuva Contabilidad Conectia</h1>
                      </div>
                    </div>
                    <div className={classes.card__content}>
                      <p>
                        Contiene la documentación técnica y recursos relacionados con las APIs en el ámbito contable mediante las que podrás integrar aplicaciones y herramientas a través de las credenciales Conectia, API
                        Swagger, ejemplos y SDK y acceso al área de soporte.
                      </p>
                      <div style={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'row-reverse' }}>
                        <HashLink smooth to='/suscripciones/5ea30d25-ce1a-4e47-afd0-5e9be22c1965'>
                          <Button styles='primary' postIcon='chevron_right_full'>
                            Saber más
                          </Button>
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.card__wrapper__subs}>
                  <div>
                    <div className={classes.card__header}>
                      <div className={classes.card__header__title}>
                        <h1>a3innuva ERP Conectia</h1>
                      </div>
                    </div>
                    <div className={classes.card__content}>
                      <p>
                        Contiene la documentación técnica y recursos relacionados con las APIs en el ámbito contable y de facturación mediante las que podrás integrar aplicaciones y herramientas a través de las credenciales Conectia, API
                        Swagger, ejemplos y SDK y acceso al área de soporte.
                      </p>
                      <div style={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'row-reverse' }}>
                        <HashLink smooth to='/suscripciones/97f7f4c2-1d01-4200-9ce0-8ef6dd931645'>
                          <Button styles='primary' postIcon='chevron_right_full'>
                            Saber más
                          </Button>
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.card__wrapper__subs}>
                  <div>
                    <div className={classes.card__header}>
                      <div className={classes.card__header__title}>
                        <h1>a3innuva Nómina Conectia</h1>
                      </div>
                    </div>
                    <div className={classes.card__content}>
                      <p>
                        Contiene la documentación técnica y recursos relacionados con las APIs en el ámbito laboral mediante las que podrás integrar aplicaciones y herramientas a través de las credenciales Conectia, API
                        Swagger, ejemplos y SDK y acceso al área de soporte.
                      </p>
                      <div style={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'row-reverse' }}>
                        <HashLink smooth to='/suscripciones/503852c4-c108-40e4-b012-e6915be1e26d'>
                          <Button styles='primary' postIcon='chevron_right_full'>
                            Saber más
                          </Button>
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.card__wrapper__subs}>
                  <div>
                    <div className={classes.card__header}>
                      <div className={classes.card__header__title}>
                        <h1>a3factura Conectia</h1>
                      </div>
                    </div>
                    <div className={classes.card__content}>
                      <p>
                        Contiene la documentación técnica y recursos relacionados con las APIs en el ámbito de facturación mediante las que podrás integrar aplicaciones y herramientas a través de las credenciales Conectia, API
                        Swagger, ejemplos y SDK y acceso al área de soporte.
                      </p>
                      <div style={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'row-reverse' }}>
                        <HashLink smooth to='/suscripciones/8fd82d57-5871-4cbc-88e4-2cf071b6833e'>
                          <Button styles='primary' postIcon='chevron_right_full'>
                            Saber más
                          </Button>
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 style={{ padding: '2rem 5rem 3rem', fontSize: '1.25rem', textAlign: 'right' }}>
              ¿Te gustaría formar parte del ecosistema de Wolters Kluwer? Consulta el programa
              {' '}
              <a href='/app-partners' rel='noreferrer'>aquí</a>
            </h1>
          </div>
        </section>
      </div>
    </>
  );
}

export default Subscriptions;
