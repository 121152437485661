/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { getAppPartnersContent } from '../../../redux/actions/appPartnersAction';
import BannerStatic from '../../../components/Banner/BannerStatic';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import config from '../../../services/config';

moment.locale('es');

function AppPartners({ setOpenForm }) {
  const dispatch = useDispatch();
  const { appPartnersPage } = useSelector((state) => state.appPartners);
  useEffect(() => {
    if (appPartnersPage && Object.keys(appPartnersPage).length === 0) {
      dispatch(getAppPartnersContent());
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Ventajas APP Partner en a3Marketplace | Wolters Kluwer</title>
        <meta name='description' content='Explora las ventajas de ser un APP Partner en a3Marketplace de Wolters Kluwer. Aprovecha las oportunidades.' />
        <link rel='canonical' href={`${config.baseUrlFront}/app-partners`} />
      </Helmet>
      <div className='div__container__pt'>
        <div>
          <BannerStatic
            title='App Partners'
            img=''
          />
          <div className='w-full' style={{ marginTop: '1em' }}>
            <Breadcrumb crumbs={[
              { title: 'Inicio', link: '/' },
              { title: 'App Partners', link: '/app-partners' },
            ]}
            />
          </div>
        </div>
        <iframe
          title='app-partners-content'
          name='app-partners-content'
          id='app-partners-content'
          src='./app-partners-iframe/index.html'
          width='100%'
          height='1980px'
          style={{ border: 0 }}
        />
      </div>
    </>
  );
}

export default AppPartners;

