/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Alert } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import emailAction from '../../redux/actions/emailAction';
import 'yup-phone';
import Base from './Base';
import { getHomeContent } from '../../redux/actions/homeAction';
import classes from './footer.module.scss';
import Button from '../Buttons/Button';
import InputUI from '../Input/InputUI/InputUI';
import TextAreaUI from '../Input/InputUI/TextAreaUI';
import config from '../../services/config';
import CustomIcon from '../MdIcon/CustomIcon';

function Footer({ isPrivate }) {
  const { pathname } = useLocation();
  if (pathname === '/soporte' || pathname === '/documentacion' || pathname.includes('/documentacion')) return null;
  const dispatch = useDispatch();
  const email = useSelector((state) => state.email);
  const { homePage } = useSelector((state) => state.home);

  const img = '';
  const currentDate = new Date();
  const year = `${currentDate.getFullYear()}`;
  const socialLinks = [
    { link: 'https://www.facebook.com/WKa3Software', icon: <FaFacebookF /> },
    { link: 'https://twitter.com/WKa3Software', icon: <FaTwitter /> },
    { link: 'https://www.youtube.com/c/WoltersKluwerTaxAccountingEspa%C3%B1a', icon: <FaYoutube /> },
    { link: 'https://www.linkedin.com/showcase/wka3software/', icon: <FaLinkedinIn /> },
  ];
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [displaySubmit, setDisplaySubmit] = useState(true);

  const formik = useFormik({
    initialValues: {
      name: '',
      lastname: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      sendMailTerms: false,
      privacyCheck: false,
      wantToReceiveNews: true,
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Campo requerido').matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'No se permiten caracteres especiales o númericos').max(50, 'Se ha excedido el número de caracteres permitidos'),
      lastname: Yup.string().required('Campo requerido').matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'No se permiten caracteres especiales o númericos').max(50, 'Se ha excedido el número de caracteres permitidos'),
      email: Yup.string().email('Correo electrónico inválido').required('Campo requerido'),
      phone: Yup.string()
        .matches(/^[0-9]+$/, 'Debe contener solo números')
        .min(10, 'El número de teléfono debe tener al menos 8 dígitos')
        .max(15, 'El número de teléfono no puede tener más de 15 dígitos'),
      subject: Yup.string().required('Campo requerido').matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'No se permiten caracteres especiales o númericos').max(70, 'Se ha excedido el número de caracteres permitidos'),
      message: Yup.string().required('Campo requerido'),
      privacyCheck: Yup.boolean()
        .oneOf([true], 'Debes aceptar la política de privacidad')
        .required('Campo requerido'),
      wantToReceiveNews: Yup.boolean()
        .required('Campo requerido'),
    }),
    onSubmit: (values) => {
      //Handle envio de correo de contacto
      setDisplaySubmit(false);
      dispatch(emailAction.sendContactEmail(values));
    },
  });

  useEffect(() => {
    if (email?.contactEmailError?.ok === false) {
      setError(true);
      setTimeout(() => { setError(false); }, 2000);
      setDisplaySubmit(true);
    } else if (email?.contactEmail?.ok) {
      setSuccess(true);
      setTimeout(() => { setSuccess(false); }, 2000);
      setDisplaySubmit(true);
    }
  }, [email]);

  useEffect(() => {
    if (homePage && Object.keys(homePage).length === 0) {
      dispatch(getHomeContent());
    }
  }, []);

  const filterButtons = homePage && homePage.contentSections ? homePage.contentSections.filter((item) => item.title === 'formulario-informes') : [];

  const privacyForm = () => {
    return (
      <div className='row justify-center'>
        <div className='flex-sm-12 flex-md-8 flex-lg-8'>
          <div>
            <div>
              <p>INFORMACIÓN BÁSICA - Te explicamos cómo vamos a proteger y a utilizar tus datos</p>
              <p>
                <strong>Responsable: </strong>
                <a
                  className='text__primary__title font-weight-bold caption cpointer'
                  href='https://www.wolterskluwer.com/es-es/solutions/a3/politica-privacidad'
                  target='_blank'
                  rel='noreferrer'
                >
                  Wolters Kluwer Tax and Accounting España, S.L.
                </a>
              </p>
            </div>
          </div>
          <div>
            <div>
              <p>
                <strong>Finalidad: </strong>
                Gestionar tu petición, así como mantener y gestionar tu relación contractual y enviarte información comercial de productos de Wolters Kluwer o de terceras empresas colaboradoras pertenecientes a los sectores determinados en la
                {'  '}
                <a
                  className='text__primary__title font-weight-bold caption cpointer'
                  href='https://www.wolterskluwer.com/es-es/solutions/a3/politica-privacidad'
                  target='_blank'
                  rel='noreferrer'
                >
                  Política de Privacidad
                </a>
                , con tu consentimiento previo.
                {'  '}
                <a
                  className='text__primary__title font-weight-bold caption cpointer'
                  href='https://www.wolterskluwer.com/es-es/solutions/a3/politica-privacidad'
                  target='_blank'
                  rel='noreferrer'
                >
                  Más info
                </a>
              </p>
            </div>
          </div>
          <div>
            <div>
              <p>
                <strong>
                  Derechos:
                </strong>
                Puedes acceder, rectificar y suprimir tus datos cuando lo desees. Consulta el resto de derechos en la
                {'  '}
                <a
                  className='text__primary__title font-weight-bold caption cpointer'
                  href='https://www.wolterskluwer.com/es-es/solutions/a3/politica-privacidad'
                  target='_blank'
                  rel='noreferrer'
                >
                  Política de Privacidad
                </a>
                .
                {'  '}
                <a
                  className='text__primary__title font-weight-bold caption cpointer'
                  href='https://www.wolterskluwer.com/es-es/solutions/a3/politica-privacidad'
                  target='_blank'
                  rel='noreferrer'
                >
                  Más info
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const form = () => {
    return (
      <>
        {!isPrivate && (
          <Base img={img}>
            <div className={`container ${classes.footer__container}`}>
              <h1 className='h2 text__white mb-3'>¿Te interesa?</h1>
              <p style={{ fontWeight: 400 }} className='h5 text__white mb-10'>Déjanos tus datos para que nuestros expertos conecten contigo.</p>
            </div>
          </Base>
        )}
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className={classes.footer__section}>
            <div className={`container ${classes.footer__section__contact}`}>
              <div className='mb-5'>
                <div className='row justify-center'>
                  <div className='container'>
                    {
                      success && (
                        <div className='row justify-center'>
                          <div className='flex-sm-12 flex-md-8 flex-lg-8 pb-5'>
                            <Alert severity='success' className='mb-5'>Datos enviados correctamente</Alert>
                          </div>
                        </div>
                      )
                    }

                    {
                      error && (
                        <div className='row justify-center'>
                          <div className='flex-sm-12 flex-md-8 flex-lg-8 pb-5'>
                            <Alert severity='error' className='mb-5'>Ups!! Ocurrio un error, vuelve a intentarlo</Alert>
                          </div>
                        </div>
                      )
                    }
                    <div className='row justify-center'>
                      <div className='flex-sm-12 flex-md-4 flex-lg-4 pb-4'>
                        <InputUI
                          name='name'
                          id='name'
                          type='text'
                          label='Nombre*'
                          touched={formik.touched.name}
                          errors={formik.errors.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                      </div>
                      <div className='flex-sm-12 flex-md-4 flex-lg-4 pb-2'>
                        <InputUI
                          name='lastname'
                          id='lastname'
                          type='text'
                          label='Apellidos*'
                          touched={formik.touched.lastname}
                          errors={formik.errors.lastname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastname}
                        />
                      </div>
                    </div>
                    <div className='row justify-center'>
                      <div className='flex-sm-12 flex-md-4 flex-lg-4 pb-2'>
                        <InputUI
                          name='email'
                          id='email'
                          type='email'
                          label='Correo electrónico*'
                          touched={formik.touched.email}
                          errors={formik.errors.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                      </div>
                      <div className='flex-sm-12 flex-md-4 flex-lg-4 pb-2'>
                        <InputUI
                          name='phone'
                          id='phone'
                          type='text'
                          label='Teléfono de contacto'
                          touched={formik.touched.phone}
                          errors={formik.errors.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                      </div>
                    </div>
                    <div className='row justify-center'>
                      <div className='flex-sm-12 flex-md-8 flex-lg-8 pb-2'>
                        <InputUI
                          name='subject'
                          id='subject'
                          type='text'
                          label='Asunto*'
                          touched={formik.touched.subject}
                          errors={formik.errors.subject}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subject}
                        />
                      </div>
                    </div>
                    <div className='row justify-center'>
                      <div className='flex-sm-12 flex-md-8 flex-lg-8'>
                        <TextAreaUI
                          name='message'
                          id='message'
                          label='Mensaje'
                          touched={formik.touched.message}
                          errors={formik.errors.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                          counter={50}
                        />
                      </div>
                    </div>
                    {
                      privacyForm()
                    }
                    <div className='row justify-center'>
                      <div className='flex-sm-12 flex-md-8 flex-lg-8'>
                        <input
                          type='checkbox'
                          name='privacyCheck'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.privacyCheck}
                        />
                        <span
                          role='button'
                          tabIndex={0}
                        >
                          He leído, entiendo y acepto
                          {'  '}
                          <a
                            className='text__primary__title font-weight-bold caption cpointer'
                            target='_blank'
                            href='https://www.wolterskluwer.com/es-es/solutions/a3/politica-privacidad'
                            rel='noreferrer'
                          >
                            la política de privacidad
                          </a>
                          <span>*</span>
                        </span>
                      </div>
                    </div>
                    <div className='row justify-center'>
                      <div className='flex-sm-12 flex-md-8 flex-lg-8'>
                        <label>
                          <span>
                            Quiero recibir novedades, contenidos exclusivos gratuitos (incluso vía electrónica) y ofertas especiales del
                            {'   '}
                            <a
                              className='text__primary__title font-weight-bold caption cpointer'
                              href='https://www.wolterskluwer.com/es-es/solutions/a3/politica-privacidad'
                              target='_blank'
                              rel='noreferrer'
                            >
                              Wolters Kluwer Tax and Accounting
                            </a>
                          </span>
                          <span className='hs-form-required'>
                            *
                          </span>
                        </label>
                        <div className='mt-2'>
                          <ul className='inputs-list multi-container' style={{ listStyle: 'none' }}>
                            <li className='hs-form-radio'>
                              <label className='hs-form-radio-display'>
                                <input
                                  className='hs-input'
                                  type='radio'
                                  value={true}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    if (!formik.values.wantToReceiveNews) {
                                      formik.setFieldValue('wantToReceiveNews', true);
                                    }
                                  }}
                                  checked={formik.values.wantToReceiveNews === true}
                                />
                                <span>Sí</span>
                              </label>
                            </li>
                            <li className='hs-form-radio'>
                              <label className='hs-form-radio-display'>
                                <input
                                  className='hs-input'
                                  type='radio'
                                  value={false}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    if (formik.values.wantToReceiveNews) {
                                      formik.setFieldValue('wantToReceiveNews', false);
                                    }
                                  }}
                                  checked={formik.values.wantToReceiveNews === false}
                                />
                                <span>No</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-5 justify-center'>
                <div>
                  {
                    displaySubmit && filterButtons && filterButtons.length > 0 &&
                    (
                      <Button styles={formik.isValid ? filterButtons[0].buttons[0].class : 'disabled'} disabled={!formik.isValid} type='submit'>
                        {filterButtons[0].buttons[0].name}
                      </Button>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <div>
      {
        pathname === '/' &&
        form()
      }
      <div className={classes.footer}>
        <div className={`container ${classes.footer__end}`}>
          <div className={classes.logo}>
            <CustomIcon name='logowhite' />
          </div>
          <div className={classes.email}>
            <h1 className='body-1 font-weight-medium text__white mb-2'>Correo electrónico</h1>
            <p className='body-1 font-weight-bold text__white'>a3developers@wolterskluwer.com</p>
          </div>
        </div>
        <div className={`container ${classes.footer__legals}`}>
          <div className={classes.policies}>
            <p className='body-1 font-weight-bold'>
              <a href={config.legalWarningPath} target='blank' className='text__white'>Aviso Legal</a>
            </p>
          </div>
          <div className={classes.policies}>
            <p className='body-1 font-weight-bold'>
              <a href={config.termsPath} target='blank' className='text__white'>Términos de uso</a>
            </p>
          </div>
          <div className={classes.policies}>
            <p className='body-1 font-weight-bold'>
              <a href={config.privacyPolicyPath} target='blank' className='text__white'>Política de Privacidad</a>
            </p>
          </div>
          <div className={classes.policies}>
            <p className='body-1 font-weight-bold'>
              <a href={config.cookiesPolicyPath} target='blank' className='text__white'>Política de Cookies</a>
            </p>
          </div>
        </div>
        <div className={`container ${classes.footer__social}`}>
          <div className={classes.footer__social__copyright}>
            <p className='caption text-uppercase text__white mb-3'>
              &copy;
              {' '}
              <span>{year}</span>
              {' '}
              Wolters Kluwer. TODOS LOS DERECHOS RESERVADOS
              {' '}
            </p>
          </div>
          <div className={classes.footer__social__icons}>
            {
              socialLinks.map((socialLink, index) => (
                <a href={socialLink.link} key={index} target='_blank' rel='noreferrer'>
                  {socialLink.icon}
                </a>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
