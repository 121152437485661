import React from 'react';
// import Icon from '../MdIcon/Icon';
import classes from './input.module.scss';

function SearchInput({ icon, borderRadius, ...rest }) {

  return (
    <div className={classes.input__wrapper}>
      <input style={{ borderRadius }} className={classes.input__search} {...rest} />
      {icon && (
        <div className={classes.input__icon}>
          <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M2.6829 2.6829C-0.894301 6.2601 -0.894301 12.0599 2.6829 15.6371C6.2601 19.2143 12.0599 19.2143 15.6371 15.6371C19.2143 12.0599 19.2143 6.2601 15.6371 2.6829C12.0599 -0.894301 6.2601 -0.894301 2.6829 2.6829ZM4.09712 4.09712C6.89327 1.30096 11.4267 1.30096 14.2229 4.09712C17.019 6.89327 17.019 11.4267 14.2229 14.2229C11.4267 17.019 6.89327 17.019 4.09712 14.2229C1.30096 11.4267 1.30096 6.89327 4.09712 4.09712Z' fill='#53565A' />
            <path d='M20.0943 18.2825L15.9649 14.153C15.0221 13.2102 13.6078 14.6244 14.5506 15.5672L18.6801 19.6967C19.6229 20.6395 21.0371 19.2253 20.0943 18.2825Z' fill='#14234B' />
          </svg>
        </div>
      )}
    </div>
  );
}

export default SearchInput;
