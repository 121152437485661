import React from 'react';
import './breadcrumb.scss';

function Breadcrumb({ crumbs }) {
  return (
    <div className='breadcrumb-wrapper'>
      <div className='col-lg-12'>
        <div className='component breadcrumb breadcrumb-navigation js-breadcrumb-navigation initialized'>
          <div className='component-content'>
            <nav>
              <ol>
                {crumbs.map((crumb, index) => (
                  <li key={index} className='breadcrumb-navigation__item'>
                    {crumb.link ? (
                      <a className='breadcrumb-navigation__link' href={crumb.link} data-dm-wk='breadcrumb.link'>
                        <span className='breadcrumb-navigation__backtotext'>
                          {crumb.title}
                        </span>
                      </a>
                    ) : (
                      <a>
                        <span className='breadcrumb-navigation__link'>{crumb.title}</span>
                      </a>
                    )}
                    {index < crumbs.length - 1 && (
                      <span className='breadcrumb-navigation__separator separator'> &gt; </span>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
