import React from 'react';
import { Helmet } from 'react-helmet';
import classes from './contacto.module.scss';
import BannerStatic from '../../../components/Banner/BannerStatic';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import config from '../../../services/config';

function Contacto(props) {

  return (
    <>
      <Helmet>
        <title>Soporte a3developers contacta con nosotros | Wolters Kluwer</title>
        <meta name='description' content='Obtén soporte en a3developers de Wolters Kluwer. Contacta con nuestro equipo de atención al cliente.' />
        <link rel='canonical' href={`${config.baseUrlFront}/soporte`} />
      </Helmet>

      <div className='div__container__pt'>
        <BannerStatic
          title='Soporte'
          img=''
        />
        <div className='w-full' style={{ marginTop: '1em' }}>
          <Breadcrumb crumbs={[
            { title: 'Inicio', link: '/' },
            { title: 'Soporte', link: '/soporte' },
          ]}
          />
        </div>
      </div>
      <iframe
        className={classes.contact__iframe}
        title='Contact Form'
        src='https://forms.office.com/pages/responsepage.aspx?id=kWzHivHn_0GonDVTstosF9LJZ4ctACBMskZ9JKMrGD5UOVQ0WldOUkk4TU9WTDNOVkdQTkhXTklVTy4u&embed=true'
      />
    </>
  );
}

export default Contacto;
