const userConstants = {
  SAVE_USER: 'SAVE_USER',

  CONFIRM_ACCOUNT_REQUEST: 'CONFIRM_ACCOUNT_REQUEST',
  CONFIRM_ACCOUNT_SUCCESS: 'CONFIRM_ACCOUNT_SUCCESS',
  CONFIRM_ACCOUNT_FAILURE: 'CONFIRM_ACCOUNT_FAILURE',

  LOGOUT_USER: 'LOGOUT_USER',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  RESTORE_SIGNUP_FAILURE: 'RESTORE_SIGNUP_FAILURE',
  RESET_SIGNUP: 'RESET_SIGNUP',
  RESET_ALERT: 'RESET_ALERT',

  RESET_PASSWORD_TICKET_SUCCESS: 'RESET_PASSWORD_TICKET_SUCCESS',
  RESET_PASSWORD_TICKET_FAILURE: 'RESET_PASSWORD_TICKET_FAILURE',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  HEAD_ETAG_SUCCESS: 'HEAD_ETAG_SUCCESS',
  HEAD_ETAG_FAILURE: 'HEAD_ETAG_FAILURE',

  SESSION_TIMEOUT: 'SESSION_TIMEOUT',
};

export default userConstants;

